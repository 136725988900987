import {useUserStore} from "~/store/user";

export function printLivraison(dateFin, dateDebut) {
    const moment = useNuxtApp().$moment;

    const fin = moment(dateFin);
    const debut = moment(dateDebut);

    if (Math.ceil((fin - debut) / (1000 * 60 * 60 * 24)) > 60) {
        const quarter = Math.floor((fin.get('month') + 3) / 3);
        let article = (quarter === 1) ? 'er' : (quarter === 2) ? 'nd' : 'ème';
        return quarter + article + ' Trimestre ' + fin.get('year');
    } else if (Math.ceil(Math.abs(fin - debut) / (100 * 60 * 60 * 24)) > 2) {
        const month = fin.format('MMMM YYYY');
        return month[0].toUpperCase() + month.slice(1)
    }

    return "le " + debut.format('D MMMM YYYY');
}

// TODO: on peut pas prendre Bien en paramètre ? ça éviterait les douze lignes dans le template
export function processShortCode(
    text, {
        surface,
        etage,
        nbPiece,
        dateLivraisonFin,
        dateLivraisonDebut,
        commune,
        pinel
    }
) {
    return text
        ?.replaceAll("[type]", getTypologieLot({surface, nbPiece}))
        ?.replaceAll('[surface]', frenchizer(surface))
        ?.replaceAll('[etage]', etage)
        ?.replaceAll('[livraison]', printLivraison(dateLivraisonFin, dateLivraisonDebut))
        ?.replaceAll('[ville]', commune)
        ?.replaceAll('[fiscalite]', pinel ? "Pinel" : "LMNP");
}

export function getTypologieLot(lot, prefix = null) {
    if (lot.surface < 30 && lot.nbPiece === 1) {
        return "Studio";
    } else {
        return (prefix ? 'Appartement ' : '') + `T${lot.nbPiece}`;
    }
}

export function getBienRoute(bien) {
    return {
        name: 'catalogue-slug-id',
        params: {slug: 'investissement-locatif-' + bien.programme.commune.libelle.replace(/ /g, '-'), id: bien.slug}
    };

}

export function callSelectBien(slug, override = true) {
    return useNuxtApp().$axios.post(`/mon-compte/bien/bien-selectionne/select/${slug}`, {override: override})
        .then(() => {
            useUserStore().hasInShoppingCart = true;
        }).catch((e) => {
            console.error(e);
        });
}

export function callDeselectBien(slug) {
    return useNuxtApp().$axios.post(`/mon-compte/bien/bien-selectionne/deselect/${slug}`)
        .then(() => {
            useUserStore().hasInShoppingCart = false;
        }).catch((e) => {
            console.error(e);
        });
}

export function callToggleFavoris(slug) {
    return new Promise(resolve => {
        useNuxtApp().$axios.post(`/mon-compte/bien/favoris/${slug}`).then(({data}) => {
            resolve(data);
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            resolve();
        });
    });
}

export function colorBgEtiquette(etiquettes) {
    if (etiquettes.includes("deja_loue")) {
        return "bg-blue7";
    }

    if (etiquettes.includes("nouveaute")) {
        return "bg-forest5";
    }

    if (etiquettes.includes("dernier")) {
        return "bg-tangerine";
    }

    if (etiquettes.includes("retour")) {
        return "bg-rose";
    }


    return "";
}

export function etiquetteText(etiquettes) {
    if (etiquettes.includes("deja_loue")) {
        return "Déjà loué";
    }

    if (etiquettes.includes("nouveaute")) {
        return "Nouveauté";
    }

    if (etiquettes.includes("dernier")) {
        return "Derniers lots";
    }

    if (etiquettes.includes("retour")) {
        return "Retour à la vente";
    }


    return "";
}

